<template>
    <div>
        <input type="text" v-model="nome" />
        <p>
            O nome é: {{nome}}
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nome: 'Fleek Cursos'
        }
    }
}
</script>